// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";

// iziToast
@import '~izitoast/dist/css/iziToast.min.css';

// Font awesome
// @import "~font-awesome/scss/font-awesome.scss";

// Sweetalert
@import "~sweetalert2/dist/sweetalert2.css";

// Overide Styles
@import 'style';

.fas, .far, .fab, .fal {
    font-size: 17px;
}

.bg-theme-primary {
    background-color: color(theme-primary);
}

.active .bs-stepper-circle {
    background-color: color(theme-primary) !important;
}


.image-container {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}


.wrap-image {
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;

}

.form-container {
    width: 560px;
    min-height: 100vh;
    display: block;
    background-color: #FFFFFF;
    padding: 45px 55px 55px 55px;
}

.img-container {
    width: calc(100% - 560px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.img-container::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
}

.cursor-pointer {
    cursor: pointer;
}