.tab-content {
  &.no-padding {
    > .tab-pane {
      padding: 0;
    }
  }
  > .tab-pane {
    line-height: 28px;
  }
}

ul.nav-tabs {
  li.nav-item {
    a.nav-link {
      i {
        color: color(primary);
      }
      span {
        display: block;
        line-height: 60%;
        i {
          font-size: 16px!important;
        }
      }
    }
  }
}

ul.nav-tabs {
  li.nav-item {
    a.nav-link.active {
      i {
        color: color(dark);
      }
    }
  }
}
