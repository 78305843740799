.page-item {
  .page-link {
    color: color(primary);
    border-radius: 3px;
    margin: 0 3px;
  }
  &.active .page-link {
    background-color: color(primary);
    border-color: color(primary);
  }
  &.disabled .page-link {
    border-color: transparent;
    background-color: color_lighten(primary, 31.7%);
    color: color(primary);
    opacity: .6;
  }
}

.page-link {
  border-color: transparent;
  background-color: color_lighten(primary, 31.7%);
  font-weight: 600;
  &:hover {
    background-color: color(primary);
    color: #fff;
    border-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}
