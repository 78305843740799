$colors: (
  primary:    		#4c4c4c,
  secondary:      #cdd3d8,
  success:        #47c363,
  info:           #3abaf4,
  warning:        #ffa426,
  danger:         #fc544b,
  light:          #e3eaef,
  grey:  		      #868e96,
  whitesmoke:     #f7f9f9,
  dark:       		#191d21,
  muted:          #98a6ad,
  body:           #f4f6f9,
  font:           #6c757d,
  fontdark:       #34395e,
  white:          #ffffff,
  theme-primary:      #b6003a,

  // Form Control
  formcontrol_normal_background:    #fdfdff,
  formcontrol_normal_border:        #e4e6fc,
  formcontrol_focus_background:     #fefeff,
  formcontrol_focus_border:         #95a0f4,

  // Utilities
  section_title_line: #a22243,
);

$font_family:   'Nunito', 'Segoe UI', arial;
$font_weight:   400;
$font_size:     14px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

