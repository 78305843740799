.swal-confirm-button {
  background-color: color(primary) !important;
  padding: 7px 17px !important;
  box-shadow: none !important;
}

.swal-cancel-button {
  background-color: color(theme-primary) !important;
  padding: 7px 17px !important;
}

.swal2-icon.swal2-warning {
  border-color: lighten(color(theme-primary), 10%) !important;
  color: lighten(color(theme-primary), 5%) !important;
}

.swal2-title {
  font-size: 1.475em !important;
}

.swal2-styled.swal2-confirm {
  background-color: color(primary) !important;
  box-shadow: none !important;
}
